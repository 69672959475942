import type React from 'react'

export const Mastodon = ({ style }: { style?: React.CSSProperties }) => (
	<svg
		width="61.076954mm"
		height="65.47831mm"
		viewBox="0 0 216.4144 232.00976"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		style={style}
		title="Mastodon logo"
	>
		<path
			style="fill:currentColor;fill-opacity:1"
			d="M 107.8656 0 C 78.20438 0.24249976 49.672527 3.4544558 33.045044 11.090698 C 33.045044 11.090698 0.067749023 25.841925 0.067749023 76.171875 C 0.067749023 87.696863 -0.1562594 101.47696 0.20874023 116.0907 C 1.406239 165.31065 9.2319251 213.81927 54.73938 225.86426 C 75.721859 231.418 93.737724 232.58156 108.24646 231.78406 C 134.55768 230.32531 149.328 222.39441 149.328 222.39441 L 148.46008 203.30383 C 148.46008 203.30383 129.65749 209.23202 108.54126 208.50952 C 87.62003 207.79202 65.533404 206.25385 62.149658 180.56763 C 61.837158 178.31138 61.680908 175.898 61.680908 173.36426 C 61.680908 173.36426 82.218985 178.38453 108.24646 179.57703 C 124.16144 180.30702 139.08565 178.64468 154.24438 176.83594 C 183.31435 173.36469 208.626 155.45315 211.80725 139.08691 C 216.81974 113.30569 216.40686 76.171875 216.40686 76.171875 C 216.40686 25.841925 183.4314 11.090698 183.4314 11.090698 C 166.80516 3.4544558 138.25558 0.24249976 108.59436 0 L 107.8656 0 z M 74.296875 39.325561 C 86.651862 39.325561 96.006749 44.074257 102.19299 53.572998 L 108.20801 63.654785 L 114.22302 53.572998 C 120.40802 44.074257 129.7629 39.325561 142.11914 39.325561 C 152.79663 39.325561 161.39998 43.079118 167.96997 50.401611 C 174.33871 57.724104 177.50976 67.622527 177.50976 80.077514 L 177.50976 141.01868 L 153.36548 141.01868 L 153.36548 81.868286 C 153.36548 69.399548 148.11947 63.070678 137.62573 63.070678 C 126.02324 63.070678 120.20874 70.577378 120.20874 85.422363 L 120.20874 117.79907 L 96.207275 117.79907 L 96.207275 85.422363 C 96.207275 70.577378 90.39094 63.070678 78.788452 63.070678 C 68.294712 63.070678 63.048706 69.399548 63.048706 81.868286 L 63.048706 141.01868 L 38.904419 141.01868 L 38.904419 80.077514 C 38.904419 67.622527 42.076051 57.724104 48.446045 50.401611 C 55.014788 43.079118 63.618135 39.325561 74.296875 39.325561 z "
		/>
	</svg>
)
